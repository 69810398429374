import React from 'react';
import {BrowserRouter as Router} from 'react-router-dom';

// import { WebSocketLink } from 'apollo-link-ws';
// import { split } from 'apollo-link';
// import { getMainDefinition } from 'apollo-utilities';

// import {ApolloClient} from 'apollo-client';
// import {ApolloProvider} from 'react-apollo';

import Header from './components/Header.js';
import Routers from './Routes';

// import {HttpLink} from 'apollo-link-http';
// import {InMemoryCache} from 'apollo-cache-inmemory';

// import {Provider} from 'react-redux';
// import { compose } from 'redux';

// import reduxThunk from 'redux-thunk';

// import reducers from './reducers';
// import host from './host';

// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// const httpLink = new HttpLink({
//   uri: `http://${host}/graphql`,
//   credentials: 'include',
// });

// for subscription
// const wsLink = new WebSocketLink({
//   uri: `ws://localhost:3000/`,
//   options: {
//     reconnect: true
//   }
// });

// const link = split(
//   // split based on operation type
//   ({ query }) => {
//     const definition = getMainDefinition(query);
//     return (
//       definition.kind === 'OperationDefinition' &&
//       definition.operation === 'subscription'
//     );
//   },
//   wsLink,
//   httpLink,
// );

// const client = new ApolloClient({
//   // link: httpLink,
//   link,
//   cache: new InMemoryCache()
// })

// commint for subscription

// const client = new ApolloClient({
//   link: httpLink,
//   cache: new InMemoryCache()
// })

// const store = createStore(
//   reducers,
//   composeEnhancers(applyMiddleware(reduxThunk))
// );

function App() {
  return (
    <Router>
      <Header />
      <Routers />
    </Router>
  );
}

export default App;
