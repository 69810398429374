import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import {initReactI18next} from 'react-i18next';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // we init with resources
    resources: {
      en: {
        translations: {
          HomepageWelcome: 'Welcome to our application',
          HomepageInfo:
            'This application can help you to create schedules for your employees.',
          HomepageInstruction:
            'Here you can watch video instruction, how the application works.',
          HomepageLangEng: 'English',
          HomepageLangRus: 'Russian',
          SignIn: 'Sign IN',
          SignUp: 'Sign UP',

          Login: 'Login',
          Email: 'Email',
          Password: 'Password',
          LoginButton: 'Login',
          wrongEmail: 'not found, please retry.',
          wrongPassword: ' and password do not match, please retry.',

          registrationTitle: 'Registration',
          registrationName: 'Name',
          registrationEmail: 'Email',
          registrationPassword: 'Password',
          registrationConfirmPassword: 'Confirm Password',
          registrationButton: 'Sign Up',
          existEmail: 'is taken already',
          notEqualPassword: 'Password does not equal the confirm Password!',
          badPassword: 'Weak password (example: 123afDD@#)',

          account: 'My account',
          logout: 'Logout',
          users: 'Users',
          schedules: 'Schedules',

          welcome: 'Welcome to our application',
          'welcome email': 'Your email:',
          'Update password': 'Update password',
          'Delete your account': 'Delete your account',
          accountPassord: 'Password',
          accountNewPassword: 'New Password',
          accountConfirmPassword: 'Confirm Password',
          'Do you realy want to delete': 'Do you realy want to delete',
          'your account': 'your account',
          'All your data will be destroyed': 'All your data will be destroyed',
          Yes: 'Yes',
          No: 'No',
          Close: 'Close',

          'Add User': 'Add User',
          'Name of user': 'Name of user',
          'Full Name': 'Full Name',
          fullname: 'Full Name',
          namePosition: 'Position',
          Position: 'Position',
          'Rate of Work': 'Rate of Work',
          'Long Of Day': 'Long Of Day',
          'Full time': 'Full time',
          'First hours of work': 'First hours of work',
          'Second hours of work': 'Second hours of work',
          'First Lunch': 'First Lunch',
          'Second Lunch': 'Second Lunch',
          Delete: 'Delete',

          Owner: 'Owner',
          Fullname: 'Fullname',
          'Name of Position': 'Name of Position',
          'Start Work': 'Start Work',
          'End Work': 'End Work',
          'Start Second Work': 'Start Second Work',
          'End Second Work': 'End Second Work',
          'Start lunch': 'Start lunch',
          'End lunch': 'End lunch',
          'Start Second lunch': 'Start Second lunch',
          'End Second lunch': 'End Second lunch',
          Submit: 'Submit',
          // 'Do you realy want to delete': 'Do you realy want to delete',
          'the user': 'the user',

          'Add Schedule': 'Add Schedule',
          'Last five schedules.': 'Last five schedules.',
          Year: 'Year',
          Month: 'Month',
          'Name of schedule': 'Name of schedule',
          January: 'January',
          February: 'February',
          March: 'March',
          April: 'April',
          May: 'May',
          June: 'June',
          July: 'July',
          August: 'August',
          September: 'September',
          October: 'October',
          November: 'November',
          December: 'December',

          'You have deleted schedulel': 'You have deleted schedulel',
          'The schedule for': 'The schedule for',

          'Name for your schedule': 'Name for your schedule',
          'Title of table header': 'Title of table header',
          'The header for your table': 'The header for your table',
          'Type of week hours': 'Type of week hours',
          'Amount of working hours per month':
            'Amount of working hours per month',
          'Amount of weekdays': 'Amount of weekdays',
          'Amount of weekends': 'Amount of weekends',
          'Amount of holidays': 'Amount of holidays',
          'Amount of days before holidays': 'Amount of days before holidays',
          'hours week': 'hours week',
          'The header of your table': 'The header of your table',
          'First hours': 'First hours',
          'Second hours': 'Second hours',
          'First lunch': 'First lunch',
          'Second lunch': 'Second lunch',
          Fulltime: 'Fulltime',
          'Long of day': 'Long of day',
          'List of users': 'List of users',
          'Select All': 'Select All',
          'Clear All': 'Clear All',
          "You don't choose any user yet.": "You don't choose any user yet.",

          'Edit chosen field(s)': 'Edit chosen field',
          'Add Employee': 'Add Employee',
          'Delete the schedule': 'Delete the schedule',
          'Select all': 'Select all',
          'Clear selection': 'Clear selection',
          'Download ODT file': 'Download ODT file',
          Edit: 'Editing',
          'Hours of Month': 'Hours of Month',
          Lunch: 'Lunch',
          Total: 'Total',
          Days: 'Days',
          plan: 'plan',
          work: 'work',
          must: 'must',
          nt: 'nt',
          holi: 'holi',

          Add: 'Add',

          days: 'day(s)',
          'You have to choose': 'You have to choose',
          'some days': 'some day(s)',
          'You have chosen': 'You have chosen',

          '1 start work': '1 start work',
          '1 stop work': '1 stop work',
          '2 start work': '2 start work',
          '2 stop work': '2 stop work',
          'Business Trip (BT)': 'Business Trip (BT)',
          'Scholastic (S)': 'Scholastic (S)',
          'Addition Scholastic (AS)': 'Addition Scholastic (AS)',
          'Unknown reason (UR)': 'Unknown reason (UR)',
          'Absenteeism (A)': 'Absenteeism (A)',
          'Manager allow (MA)': 'Manager allow (MA)',
          'Scholastic vacation (SV)': 'Scholastic vacation (SV)',
          'Disease (D)': 'Disease (D)',
          'Vacation (V)': 'Vacation (V)',
          'Child care (CC)': 'Child care (CC)',
          'Administration vacation (AV)': 'Administration vacation (AV)',
          'Overtime (O)': 'Overtime (O)',
          'Night time (NT)': 'Night time (NT)',
          'Working (W)': 'Working (W)',
          all: 'all',

          BT: 'BT',
          S: 'S',
          AS: 'AS',
          UR: 'UR',
          A: 'A',
          MA: 'MA',
          SV: 'SV',
          D: 'D',
          V: 'V',
          CC: 'CC',
          AV: 'AV',
          O: 'O',
          NT: 'NT',

          // 'Do you realy want to delete': 'Do you realy want to delete',
          'the schedule': 'the schedule ',
          'The schedule': 'The schedule',
          'was created successfully': 'was created successfully',
          'Check your list of schedules': 'Check your list of schedules',

          amountOfWorkingHours: 'Amount of Working Hours',

          'Page not found': 'Page not found',

          'Query error title': 'Query Error',
          'Mutation error title': 'Mutation Error',
          'Error info':
            'You can find more information in the console about this error.',
        },
      },
      ru: {
        translations: {
          HomepageWelcome: 'Добро пожаловать в наше приложение.',
          HomepageInfo:
            'Эта программа поможет вам создавать графики работы для ваших специалистов.',
          HomepageInstruction:
            'Здесь вы можете посмотреть видео инструкцию, как работать в программе.',
          HomepageLangEng: 'Английский',
          HomepageLangRus: 'Русский',

          SignIn: 'Вход',
          SignUp: 'Регистрация',

          Login: 'Авторизоваться',
          Email: 'Электронная Почта',
          Password: 'Пароль',
          LoginButton: 'Войти',
          wrongEmail: 'не найдена, попробуйте ещё раз.',
          wrongPassword: ' и пароль не подходят, попробуйте ещё раз.',

          registrationTitle: 'Регистрация',
          registrationName: 'Ваше Имя',
          registrationEmail: 'Электронная Почта',
          registrationPassword: 'Пароль',
          registrationConfirmPassword: 'Повторить пароль',
          registrationButton: 'Зарегистрироваться',
          existEmail: 'уже используется другим пользователем',
          notEqualPassword: 'Пароли не совпадают',
          badPassword: 'Слабый пароль (пример: 123asdfDD@#), ',

          account: 'Мой аккаунт',
          logout: 'Выход',
          users: 'Работники',
          schedules: 'Графики',

          welcome: 'Добро пожаловать в наше приложение',
          'welcome email': 'Ваша электронная почта:',
          'Update password': 'Установить новый пароль',
          'Delete your account': 'Удалить ваш аккаунт',
          accountPassord: 'Пароль',
          accountNewPassword: 'Ваш новый пароль',
          accountConfirmPassword: 'Повторить пароль',
          'Do you realy want to delete': 'Вы действительно хотите удалить ',
          'your account': 'ваш аккаунт',
          'All your data will be destroyed': 'Все ваши данные будут стёрты',
          Yes: 'Да',
          No: 'Нет',
          Close: 'Закрыть',

          'Add User': 'Добавить работника',
          'Name of user': 'Имя работника',
          'Full Name': 'Имя',
          fullname: 'Имя',
          Position: 'Должность',
          namePosition: 'Должность',
          'Rate of Work': 'Ставка',
          'Long Of Day': 'Длинна раб. дня',
          'Full time': 'Полный день',
          'First hours of work': 'Первые часы работы',
          'Second hours of work': 'Вторые часы работы',
          'First Lunch': 'Первый обед',
          'Second Lunch': 'Второй обед',
          Delete: 'Удалить',

          Owner: 'Владелец',
          Fullname: 'Имя',
          'Name of Position': 'Должность',
          'Start Work': 'Начало работы',
          'End Work': 'Конец работы',
          'Start Second Work': 'Начало второй работы',
          'End Second Work': 'Конец второй работы',
          'Start lunch': 'Начало обеда',
          'End lunch': 'Конец обеда',
          'Start Second lunch': 'Начало второго обеда',
          'End Second lunch': 'Конец второго обеда',
          Submit: 'Отправить',
          // 'Do you realy want to delete': 'Вы действительно хотите удалить ',
          'the user': 'работника',

          'Add Schedule': 'Добавить график',
          'Last five schedules.': 'Пять последних графиков.',
          Year: 'Год',
          Month: 'Месяц',
          'Name of schedule': 'Название графика',
          January: 'Январь',
          February: 'Февраль',
          March: 'Март',
          April: 'Апрель',
          May: 'Май',
          June: 'Июнь',
          July: 'Июль',
          August: 'Август',
          September: 'Сентябрь',
          October: 'Октябрь',
          November: 'Ноябрь',
          December: 'Декабрь',

          'You have deleted schedulel': 'Вы удалили график',
          'The schedule for': 'График на',

          'Name for your schedule': 'Название графика',
          'Title of table header': 'Название таблицы',
          'The header for your table': 'Заголовок таблицы',
          'Type of week hours': 'Длительность рабочего дня',
          'Amount of working hours per month':
            'Общее количесво рабочих часов в месяц',
          'Amount of weekdays': 'Рабочие дни',
          'Amount of weekends': 'Выходные дни',
          'Amount of holidays': 'Праздничные дни',
          'Amount of days before holidays':
            'Предпразничные дни (сокращенные на час)',
          'hours week': 'часовой рабочий день',
          'The header of your table': 'Заголовок таблицы',
          'First hours': '1 раб. часы',
          'Second hours': '2 раб. часы',
          'First lunch': 'Первый обед',
          'Second lunch': 'второй обед',
          Fulltime: 'Полный день',
          'Long of day': 'Длинна дня',
          'List of users': 'Список работников',
          'Select All': 'Выбрать всех',
          'Clear All': 'Сбросить',
          "You don't choose any user yet.":
            'Вы ещё не выбрали не одного работника.',

          'Edit chosen field': 'Редактировать ячейк(у)и',
          'Add Employee': 'Добавить работника',
          'Delete the schedule': 'Удалить график',
          'Select all': 'Выбрать все ячейки',
          'Clear selection': 'Очистить все ячейки',
          'Download ODT file': 'Загрузить ODT файл',
          Edit: 'Изменить',
          'Hours of Month': 'Месяц план. часы',
          Lunch: 'Обед',
          Total: 'Итого',
          Days: 'Дней',
          plan: 'план',
          work: 'раб',
          must: 'долж',
          nt: 'ноч',
          holi: 'праз',

          Add: 'Доб.',

          days: 'дней',
          'You have to choose': 'Вы должны выбрать',
          'some days': 'день или несколько дней',
          'You have chosen': 'Вы выбрали',

          '1 start work': '1 начало раб.',
          '1 stop work': '1 конец раб.',
          '2 start work': '2 начало раб.',
          '2 stop work': '2 конец раб.',
          'Business Trip (BT)': 'Служеб. командировки (К)',
          'Scholastic (S)': 'Выходной по учебе (ВУ)',
          'Addition Scholastic (AS)': 'Учебный доп. отпуск (ОУ)',
          'Unknown reason (UR)': 'Невыясненные причины (НН)',
          'Absenteeism (A)': 'Прогул (П)',
          'Manager allow (MA)': 'Гос. обязанность (Г)',
          'Scholastic vacation (SV)': 'Праздн. день (РП)',
          'Disease (D)': 'Болезнь (Б)',
          'Vacation (V)': 'Отпуск (О)',
          'Child care (CC)': 'Уход за ребенком (ОР)',
          'Administration vacation (AV)': 'Адм. отпуск (А)',
          'Overtime (O)': 'Сверхур. работы (С)',
          'Night time (NT)': 'Ночное время (Н)',
          'Working (W)': 'Фак. отраб.(Ф)',
          all: 'все',

          BT: 'К',
          S: 'ВУ',
          AS: 'ОУ',
          UR: 'НН',
          A: 'П',
          MA: 'Г',
          SV: 'РП',
          D: 'Б',
          V: 'О',
          CC: 'ОР',
          AV: 'А',
          O: 'С',
          NT: 'Н',

          // 'Do you realy want to delete': 'Вы действительно хотите удалить',
          'the schedule': 'график ',
          'The schedule': 'График',
          'was created successfully': 'был успешно создан',
          'Check your list of schedules': 'Проверте ваш список графиков',

          amountOfWorkingHours: 'Количество рабочих часов Месяц/План',

          'Page not found': 'Старница не найдена',

          'Query error title': 'Ошибка запроса данных',
          'Mutation error title': 'Ошибка изменения данных',
          'Error info':
            'Вы можете найти больше информации об этой ошибки в консоли.',
        },
      },
    },
    fallbackLng: 'en',
    debug: false,

    // whitelist: ['en', 'ru'],
    // lowerCaseLng: false,
    // have a common namespace used around the full app
    ns: ['translations'],
    defaultNS: 'translations',

    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
